import type { MenuDataItem } from 'egenie-common';
import React from 'react';

export const routes: MenuDataItem[] = [
  {
    children: [
      {
        exact: true,
        path: '/',
        redirect: '/egenie-erp-home/index',
      },
      {
        path: '/egenie-erp-home/index',
        title: '首页',
        exact: true,
        component: React.lazy(() => import('./pages/index')),
      },
      {
        path: '/egenie-erp-home/vendorPosHome',
        title: 'POS首页',
        exact: true,
        component: React.lazy(() => import('./pages/vendorPosHome/index')),
      }, // pos首页
      {
        path: '/egenie-erp-home/cloudWmsHome',
        title: '依链云仓',
        exact: true,
        component: React.lazy(() => import('./pages/cloudWmsHome/index')),
      }, // 云仓首页
      {
        path: '/egenie-erp-home/sclHome',
        title: 'SCL首页',
        exact: true,
        component: React.lazy(() => import('./pages/sclHome/index')),
      }, // SCL首页
      {
        path: '/egenie-erp-home/bossHome',
        title: 'BOSS首页',
        exact: true,
        component: React.lazy(() => import('./pages/bossHome/index')),
      },
      {
        path: '/egenie-erp-home/noticeCenter',
        title: '通知中心',
        exact: true,
        component: React.lazy(() => import('./pages/bossHome/noticeCenter')),
      },
      {
        path: '/egenie-erp-home/baseInfoHome',
        title: '首页',
        exact: true,
        component: React.lazy(() => import('./pages/baseInfoHome/index')),
      }, // egenie-ts-BaseInfo首页
      {
        path: '/egenie-erp-home/pgmsHome',
        title: '拿货精灵首页',
        exact: true,
        component: React.lazy(() => import('./pages/pgmsHome/index')),
      }, // 拿货系统首页
      {
        path: '/egenie-erp-home/notificationCenter',
        title: '通知中心',
        exact: true,
        component: React.lazy(() => import('./pages/notificationCenter/index')),
      }, // 通知中心
      {
        path: '/egenie-erp-home/announcement',
        title: '公告',
        exact: true,
        component: React.lazy(() => import('./pages/announcement/index')),
      }, // 公告列表
      {
        path: '/egenie-erp-home/galleryHome',
        title: '展厅首页',
        exact: true,
        component: React.lazy(() => import('./pages/galleryHome/index')),
      }, // 展厅首页
      {
        path: '/egenie-erp-home/shjHome',
        title: '衫海精首页',
        exact: true,
        component: React.lazy(() => import('./pages/shjHome/index')),
      }, // 展厅首页
      {
        path: '/egenie-erp-home/anchorHome',
        title: '直播盈-一样的直播，更高的收益!',
        exact: true,
        component: React.lazy(() => import('./pages/anchorHome/index')),
      }, // 分佣首页
    ],
  },
];
