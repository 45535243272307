import './global.less';
import { Spin } from 'antd';
import { RenderRoutes, history, Locale } from 'egenie-common';
import { init } from 'egenie-monitor-web';
import React from 'react';
import ReactDOM from 'react-dom';
import { routes } from './routes';
import NProgress from 'nprogress';

init({
  deviceId: '',
  client: 'erp',
  debug: true,
  trackDsn: '/api/bury/point/report',
  useImgUpload: true,
  silentFetch: true,
  silentConsole: true,
  silentXhr: true,
  silentError: true,
});
function render() {
  NProgress.start();

  function Internal() {
    React.useLayoutEffect(() => {
      NProgress.done();
    }, []);

    return (
      <Locale>
        <RenderRoutes
          history={history}
          loading={<Spin size="large"/>}
          routes={routes}
        />
      </Locale>
    );
  }

  ReactDOM.render(<Internal/>, document.getElementById('root'));
}
render();

if (process.env.NODE_ENV === 'development') {
  if (process.env.CLI_TOOL === 'vite') {
    // @ts-ignore
    import.meta.hot.accept();
  } else if (process.env.CLI_TOOL === 'webpack') {
    // @ts-ignore
    module.hot.accept(render);
  }
}
